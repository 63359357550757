import React from "react";

import { CountryCards } from "../components/country-cards/country-cards";
import { useCookies } from "../hooks/useCookies";
import BareLayout from "../layouts/bare";
import { isLocale } from "../utils/locale";

const CountrySelect: React.FC = () => {
    const [locale] = useCookies("NEXT_LOCALE");

    if (isLocale(locale) && locale !== "global") {
        window.location.href = `/${locale}`;
        return null;
    }

    return (
        <BareLayout>
            <CountryCards />
        </BareLayout>
    );
};

export default CountrySelect;
